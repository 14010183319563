<template>
  <div class="w-40">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 252 78.8"
      style="enable-background:new 0 0 252 78.8;"
      xml:space="preserve"
    >
      <g id="_x33_.5_x__x_2_in__x2013__2">
        <g id="Background_00000115487241379353529150000016909604628038790532_" />
        <g id="Graphic_Elements_00000044157325694491984560000001205855807968887177_" />
        <g id="Text_00000067950182210568605070000005591985946384463271_" />
      </g>
      <g id="_x33_.5_x__x_2_in__x2013__1">
        <g id="Background" />
        <g id="Graphic_Elements" />
        <g id="Text" />
        <g>
          <g>
            <path
              class="st0"
              d="M83.4,73V59.8h10.7c0.9,0,1.7,0.2,2.3,0.6c0.6,0.4,1.1,0.9,1.4,1.5c0.3,0.6,0.5,1.3,0.5,2.1
              c0,0.8-0.2,1.5-0.6,2.2c-0.4,0.7-1,1.2-1.8,1.6l2.8,5.2h-3.7l-2.3-4.6h-5.8V73H83.4z M86.7,65.8h6.4c0.5,0,1-0.2,1.3-0.5
              c0.3-0.3,0.5-0.7,0.5-1.3c0-0.4-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.4-0.6-0.6c-0.3-0.1-0.6-0.2-0.9-0.2h-6.4V65.8z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M116.9,73.2c-1.8,0-3.3-0.3-4.6-0.8c-1.3-0.5-2.3-1.3-3-2.3c-0.7-1-1-2.3-1-3.7c0-1.5,0.3-2.7,1-3.7
              c0.7-1,1.7-1.8,3-2.3c1.3-0.5,2.8-0.8,4.6-0.8c1.8,0,3.3,0.3,4.6,0.8c1.3,0.5,2.3,1.3,3,2.3c0.7,1,1,2.3,1,3.7
              c0,1.5-0.3,2.7-1,3.7c-0.7,1-1.7,1.8-3,2.3C120.2,73,118.7,73.2,116.9,73.2z M116.9,70.6c0.8,0,1.5-0.1,2.1-0.3
              c0.6-0.2,1.2-0.4,1.6-0.8c0.5-0.3,0.8-0.8,1.1-1.3c0.2-0.5,0.4-1.1,0.4-1.7v-0.4c0-0.6-0.1-1.2-0.4-1.7c-0.3-0.5-0.6-0.9-1.1-1.3
              c-0.5-0.3-1-0.6-1.6-0.8c-0.6-0.2-1.3-0.3-2.1-0.3c-0.8,0-1.5,0.1-2.1,0.3c-0.6,0.2-1.2,0.4-1.6,0.8c-0.5,0.3-0.8,0.8-1.1,1.3
              c-0.3,0.5-0.4,1.1-0.4,1.7v0.4c0,0.6,0.1,1.2,0.4,1.7c0.3,0.5,0.6,0.9,1.1,1.3c0.5,0.3,1,0.6,1.6,0.8
              C115.5,70.5,116.2,70.6,116.9,70.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M144.2,73.2c-1.8,0-3.3-0.3-4.6-0.8c-1.3-0.5-2.3-1.3-3-2.3c-0.7-1-1-2.3-1-3.7c0-1.5,0.3-2.7,1-3.7
              c0.7-1,1.7-1.8,3-2.3c1.3-0.5,2.8-0.8,4.6-0.8c1.8,0,3.3,0.3,4.6,0.8c1.3,0.5,2.3,1.3,3,2.3c0.7,1,1,2.3,1,3.7
              c0,1.5-0.3,2.7-1,3.7c-0.7,1-1.7,1.8-3,2.3C147.5,73,145.9,73.2,144.2,73.2z M144.2,70.6c0.8,0,1.5-0.1,2.1-0.3
              c0.6-0.2,1.2-0.4,1.6-0.8c0.5-0.3,0.8-0.8,1.1-1.3c0.2-0.5,0.4-1.1,0.4-1.7v-0.4c0-0.6-0.1-1.2-0.4-1.7c-0.3-0.5-0.6-0.9-1.1-1.3
              c-0.5-0.3-1-0.6-1.6-0.8c-0.6-0.2-1.3-0.3-2.1-0.3c-0.8,0-1.5,0.1-2.1,0.3c-0.6,0.2-1.2,0.4-1.6,0.8c-0.5,0.3-0.8,0.8-1.1,1.3
              c-0.3,0.5-0.4,1.1-0.4,1.7v0.4c0,0.6,0.1,1.2,0.4,1.7c0.3,0.5,0.6,0.9,1.1,1.3c0.5,0.3,1,0.6,1.6,0.8
              C142.7,70.5,143.4,70.6,144.2,70.6z"
            />
          </g>
          <g>
            <path class="st0" d="M163.4,73V59.8h12.8v2.6h-9.4v3h8.5V68h-8.5v5H163.4z" />
          </g>
          <g>
            <path class="st0" d="M186.4,73V59.8h3.3V73H186.4z" />
          </g>
          <g>
            <path
              class="st0"
              d="M201,73V59.8h3.1l7.1,6.6c0.1,0.1,0.3,0.3,0.5,0.5c0.2,0.2,0.4,0.4,0.7,0.7c0.2,0.2,0.4,0.4,0.6,0.6h0.2
              c0-0.3,0-0.6,0-1c0-0.4,0-0.7,0-1v-6.5h3.2V73h-3l-7-6.6c-0.3-0.3-0.7-0.6-1-1c-0.4-0.4-0.6-0.7-0.9-0.9h-0.2c0,0.2,0,0.4,0,0.8
              c0,0.4,0,0.8,0,1.3V73H201z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M234.9,73.2c-2.7,0-4.7-0.6-6-1.7c-1.3-1.1-2-2.8-2-5.1c0-1.5,0.3-2.8,1-3.8c0.7-1,1.7-1.8,3-2.3
              c1.3-0.5,2.9-0.8,4.7-0.8c1.2,0,2.2,0.1,3.2,0.3c1,0.2,1.8,0.5,2.5,0.9c0.7,0.4,1.3,0.9,1.7,1.5c0.4,0.6,0.6,1.3,0.6,2.2h-3.2
              c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.6-0.5-1-0.7c-0.4-0.2-0.9-0.3-1.5-0.4c-0.5-0.1-1.1-0.1-1.7-0.1c-0.8,0-1.5,0.1-2.2,0.2
              c-0.7,0.2-1.2,0.4-1.7,0.7c-0.5,0.3-0.9,0.7-1.1,1.2c-0.3,0.5-0.4,1.1-0.4,1.8v0.5c0,0.9,0.2,1.7,0.6,2.3c0.4,0.6,1,1,1.8,1.3
              c0.8,0.3,1.7,0.4,2.7,0.4c1.1,0,2-0.1,2.7-0.3c0.7-0.2,1.3-0.5,1.6-0.8s0.6-0.8,0.6-1.3V68h-5.1v-2.4h8.3V73h-2l-0.3-1.7
              c-0.5,0.4-1.1,0.8-1.7,1.1c-0.6,0.3-1.4,0.5-2.1,0.6C236.6,73.2,235.7,73.2,234.9,73.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  class="st1"
                  d="M35.7,21.1c-0.6,0-0.9-0.2-1-0.9c-0.6-4.1-4.3-7.2-9.6-7.2c-6.1,0-11,4.6-11,11.5c0,6.8,4.7,11.5,11,11.5
                  c5.4,0,8.9-2.6,9.6-6.6c0.1-0.8,0.4-1,1.1-1h5.9c0.5,0,1,0.3,1,0.9c0,7.6-7.1,13.4-17.6,13.4c-10.6,0-19-7.4-19-18.2
                  c0-10.8,8.3-18.2,19-18.2c11.2,0,17.6,7.1,17.6,14.1c0,0.4-0.3,0.8-0.9,0.8H35.7z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  class="st1"
                  d="M73.5,36.1H56.3l-2.5,5.4c-0.4,0.7-0.5,0.8-1.2,0.8h-7c-0.4,0-0.5-0.2-0.5-0.4c0-0.2,0.1-0.5,0.2-0.7
                  L61.9,6.9H68l16.6,34.2c0.1,0.2,0.2,0.5,0.2,0.7c0,0.2-0.1,0.4-0.5,0.4h-7c-0.7,0-0.9-0.1-1.2-0.8L73.5,36.1z M59.1,29.8h11.6
                  l-3.2-6.5c-1.2-2.5-2.5-6.3-2.5-6.3h-0.4c0,0-1.2,3.8-2.5,6.3L59.1,29.8z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  class="st1"
                  d="M119.7,42.2l-18-18.3c-1.9-1.9-4-4.5-4-4.5h-0.2v22.2c0,0.4-0.4,0.7-0.7,0.7h-6.6c-0.4,0-0.7-0.4-0.7-0.7
                  V7.6c0-0.4,0.4-0.7,0.7-0.7h5.4L113.5,25c1.9,1.9,4.1,4.8,4.1,4.8h0.2V7.6c0-0.4,0.4-0.7,0.7-0.7h6.6c0.4,0,0.7,0.4,0.7,0.7
                  v33.9c0,0.4-0.4,0.7-0.7,0.7H119.7z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  class="st1"
                  d="M171.3,24.6c0,11.1-8.9,18.2-19.5,18.2c-10.6,0-19.5-7.2-19.5-18.2c0-10.9,8.9-18.2,19.5-18.2
                  C162.4,6.3,171.3,13.7,171.3,24.6z M140.4,24.6c0,7.1,5.2,11.5,11.5,11.5s11.5-4.4,11.5-11.5S158.1,13,151.8,13
                  S140.4,17.4,140.4,24.6z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  class="st1"
                  d="M178.5,42.2c-0.4,0-0.7-0.4-0.7-0.7V7.6c0-0.4,0.4-0.7,0.7-0.7h15.1c8.5,0,14.6,5.1,14.6,13.2
                  c0,8.2-6.4,13-14.6,13h-7.8v8.3c0,0.4-0.4,0.7-0.7,0.7H178.5z M185.8,13.6v13.2h7.1c4.6,0,7.3-2.7,7.3-6.6
                  c0-4.1-2.9-6.5-7.3-6.5H185.8z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  class="st1"
                  d="M209.5,7.9c-0.2-0.4-0.3-0.5-0.3-0.6c0-0.2,0.1-0.4,0.4-0.4h7.5c0.7,0,1,0.1,1.4,0.7l6.1,8.5
                  c1.3,1.8,2.9,4.8,2.9,4.8h0.2c0,0,1.6-2.9,2.9-4.8l6.1-8.5c0.4-0.6,0.7-0.7,1.4-0.7h7.5c0.2,0,0.4,0.2,0.4,0.4
                  c0,0.1-0.1,0.3-0.3,0.6l-14.1,20.2v13.4c0,0.4-0.4,0.7-0.7,0.7h-6.6c-0.4,0-0.7-0.4-0.7-0.7V28.1L209.5,7.9z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <polygon class="st1" points="243.5,53.2 81.6,53.2 63.4,15.3 70.4,11.9 86.5,45.4 243.5,45.4" />
            </g>
          </g>
          <g>
            <g>
              <polygon class="st1" points="48.2,53.2 6.5,53.2 6.5,45.4 43.3,45.4 59.4,11.9 66.5,15.3" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<style type="text/css" scoped>
.st0{fill:#282A2D;}
.st1{fill:#E64825;}
</style>
